import React from 'react'
import PropTypes from 'prop-types'

const Header = (props) => (
    <header id="header" style={props.timeout ? {display: 'none'} : {}}>
        <div className="logo">
            <span className="icon fa-pencil"></span>
        </div>
        <div className="content">
            <div className="inner">
                <h1>letras - tintas</h1>
                <p>Editorial digital que se dedica a la publicaci&oacute;n de escritos y creaci&oacute;n original de contenidos.</p>
            </div>
        </div>
        <nav>
            <ul>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('intro')}}>Editorial</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('work')}}>Publicaciones</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('about')}}>Nosotros</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('contact')}}>Cont&aacute;ctanos</a></li>
            </ul>
        </nav>
    </header>
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header
