import React from 'react'
import PropTypes from 'prop-types'

import picintro from '../images/intro_book.jpg'
import picpublicaciones from '../images/publicaciones_computer.jpg'
import picnosotros from '../images/nosotros_typewriter.jpg'


class Main extends React.Component {
  render() {

    let close = <div className="close" onClick={() => {this.props.onCloseArticle()}}></div>

    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}>

        <article id="intro" className={`${this.props.article === 'intro' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Editorial</h2>
          <span className="image main"><img src={picintro} alt="" /></span>
          <p>En letras - tintas deseamos poder disfrutar de la libertad de elegir cómo queremos crear, distribuir y compartir nuestras publicaciones. La independencia en una publicación nos presenta la oportunidad de permitirnos tener una comunicación directa con el lector. La digitalización en su universo infinito, amplía las posibilidades al momento de seleccionar que vamos a leer. En la era de la comunicación digital la palabra escrita no ha perdido su valor, es el puente entre las personas, los dispositivos y la tecnología.</p>
          {close}
        </article>

        <article id="work" className={`${this.props.article === 'work' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Publicaciones</h2>
          <span className="image main"><img src={picpublicaciones} alt="" /></span>
          <ul class="items-list">
            <li>p&aacute;ginas web</li>
            <li>blogs</li>
            <li>postcast</li>
            <li>libros</li>
            <li>contenido multimedia</li>
          </ul>
          {close}
        </article>

        <article id="about" className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Nosotros</h2>
          <span className="image main"><img src={picnosotros} alt="" /></span>
          <p>Creamos en letras - tintas un espacio virtual, donde se pueda compartir publicaciones independientes de escritores y conectar con los lectores. La editorial comenzó como una idea de emprendimiento en un lugar pequeño, un escritorio, una laptop, una lámpara y una impresora. Tiempo después se añadió un equipo de diseñadores, programadores, ilustradoras y correctores.</p>
          <p>En nuestros formatos tratamos de emplear lo compacto, fácil de comprender y leer. Entre el diseño y contenido queremos lograr grandes cosas como la comunicación y la automotivación. Deseamos impulsar y mover a nuestros seguidores. Cómo editorial independiente buscamos plantear nuestras ideas al lector y esperamos de vuelta una respuesta. Nuestro mayor deseo es lograr publicar cosas relevantes y a su vez cotidianas.</p>
          <p>La linea editorial se enfoca en una voz femenina, escritos por mujeres o sobre mujeres. La distribución de nuestros trabajos de contenido y escritos se producen en su mayoría por medio de plataformas digitales. Sin embargo, recurrimos a visitar lugares poco habituales como cafés y eventos culturales.</p>
          {close}
        </article>

        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Cont&aacute;ctanos</h2>
          <form method="post" action="#">
            <div className="field half first">
              <label htmlFor="name">Nombre</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Correo Electr&oacute;nico</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Mensaje</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li><input type="submit" value="Enviar Mensaje" className="special" /></li>
              <li><input type="reset" value="Reiniciar" /></li>
            </ul>
          </form>
          <ul className="icons">
            <li><a href="https://twitter.com/letras_tintas" className="icon fa-twitter"><span className="label">Twitter</span></a></li>
            <li><a href="https://www.facebook.com/letras.tintas.pr/" className="icon fa-facebook"><span className="label">Facebook</span></a></li>
            {/* <li><a href="https://m.me/letras.tintas.pr" className="icon fa-facebook-messenger"><span className="label">Messanger</span></a></li> */}
            <li><a href="https://www.instagram.com/letras.tintas/" className="icon fa-instagram"><span className="label">Instagram</span></a></li>
            <li><a href="https://medium.com/@vanessaechandia17" className="icon fa-medium"><span className="label">Medium</span></a></li>
          </ul>
          {close}
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main